import React from "react";
import { Link } from "react-router-dom";
import style from "./Project.module.css";

function Project({ PrivacyTerms, title, description, credits, media }) {

  return (
    <div className={style.project}>
      {media.type === "image" ? (
        <img
          className={style.projectImage}
          src={media.source}
          alt={`Imagem do projeto ${title}`}
        />
      ) : media.type === "youtube" ? (
        <iframe
          className={style.projectVideo}
          src={`https://www.youtube.com/embed/${media.source}`}
          title={`Vídeo do projeto ${title}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : media.type === "linkedin" ? (
        <iframe
          className={`${style.projectVideoLinkedIn}`} // Aplica a classe adicional para vídeos do LinkedIn
          src={media.source}
          title={`Vídeo do LinkedIn ${title}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : null}
      <h3>{title}</h3>
      <p>{description}</p>
      <p>{credits}</p>
      {PrivacyTerms && (
        <p>
          <Link className={style.PrivacyTerms} to={PrivacyTerms}>
            Termos de Privacidade
          </Link>
        </p>
      )}
    </div>
  );
}

export default Project;
