import React from "react";
import Project from "../../components/Project";
import style from "./Home.module.css";
import projectData from "../../data/projects.json"; // Importe o arquivo JSON

import fotoJoao from "./foto-joao.jpg";

function Home() {
  // Ordene os projetos por data de lançamento (ou outro critério relevante)
  const sortedProjects = projectData.sort((a, b) => {
    // Assumindo que você tem uma propriedade chamada "releaseDate" nos projetos
    const dateA = new Date(a.releaseDate);
    const dateB = new Date(b.releaseDate);
    return dateB - dateA; // Classifica em ordem decrescente (do mais recente para o mais antigo)
  });

  // Pegue os dois últimos projetos
  const recentProjects = sortedProjects.slice(0, 2);

  return (
    <main>
      <div className={style.mainContainer}>
        <section className={style.presentation}>
          <img
            className={style.presentationPhoto}
            src={fotoJoao}
            alt="Foto de João"
          ></img>
          <div className={style.presentationInfo}>
            <h2 className={style.presentationTitle}>
              Olá, eu sou João Paulo Mendonça
            </h2>
            <h3 className={style.presentationSubTitle}>
              Game Designer | Modelador 3D | Pixel Artist | Front End Developer
            </h3>
            <p className={style.presentationText}>
              Bem-vindo ao meu site de currículo e portfólio! Aqui você
              encontrará informações sobre minhas habilidades e experiências em
              várias áreas. Fique à vontade para explorar e entrar em contato
              comigo para oportunidades de colaboração.
            </p>
          </div>
        </section>
        <section>
          <h2 className={style.projectNews}>Projetos Recentes</h2>
          <div className={style.projects}>
            {recentProjects.map((project) => (
              <Project
                key={project.id}
                title={project.title}
                description={project.description}
                credits={project.credits}
                media={project.media} // Use a nova estrutura de mídia do projeto
              />
            ))}
          </div>
        </section>
      </div>
    </main>
  );
}

export default Home;
