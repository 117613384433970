import style from "./Curriculum.module.css";

function Curriculum() {
  return (
    <main className={style.curriculumContainer}>
      <section className={style.summary}>
        <h3 className={style.title}>Resumo</h3>
        <p>
          Aluno do 6º período do curso de Jogos Digitais da PUC Minas, possuo
          experiência em tecnologia e jogos digitais, tanto na teoria quanto na
          prática, adquirida ao longo de minha formação acadêmica. Além disso,
          já atuei profissionalmente na área, o que me permitiu aprimorar minhas
          habilidades e conhecimentos.
        </p>
      </section>
      <section className={style.academicBackground}>
        <h3 className={style.title}>Formação Acadêmica</h3>
        <h4 className={style.subtitle}>PUC Minas, Betim - Jogos Digitais EAD</h4>
        <p>Janeiro de 2021 - Previsão de conclusão em dezembro de 2023</p>
      </section>
      <section className={style.experience}>
        <h3 className={style.title}>Experiência</h3>
        <div className={style.company}>
          <h4 className={style.subtitle}>PlayFox Games, Recife</h4>
          <p className={style.position}>
            Modelador 3D e Programador Unity Março de 2022 - Janeiro de 2023
          </p>        
          <ul className={style.list}>
            <li>Modelagem 3D de objetos e personagens</li>
            <li>Programação de jogos em C# utilizando Unity3D</li>
          </ul>
          <p className={style.description}>
            Durante minha experiência na PlayFox Games, trabalhei com modelagem
            de objetos e personagens, além de também programar jogos em C# com
            Unity3D. Colaborei na criação de jogos de qualidade, gerenciando
            prazos e utilizando minhas habilidades técnicas e criativas para
            solucionar problemas complexos e atingir objetivos da equipe.
          </p>
        </div>

        <div className={style.company}>
          <h4 className={style.subtitle}>PUC Minas, Belo Horizonte</h4>
          <p className={style.position}>Auxiliar Administrativo</p>
          <p className={style.period}>Agosto de 2010 - Maio de 2021</p>
          <ul className={style.list}>
            <li>
              Criação de documentos como atas de reuniões e planilhas de
              gerenciamento
            </li>
            <li>
              Atendimento a alunos e professores, fornecendo suporte e
              orientação para suas necessidades
            </li>
            <li>
              Organização e manutenção de arquivos e documentos importantes
            </li>
          </ul>
          <p className={style.description}>
            Na minha posição como Auxiliar Administrativo na PUC Minas, tive
            várias responsabilidades, incluindo a criação de atas de reuniões e
            planilhas de gerenciamento. Também ofereci suporte e orientação a
            alunos e professores, garantindo que suas necessidades fossem
            atendidas de forma eficiente. Além disso, mantive a organização de
            arquivos e documentos importantes para o bom funcionamento da
            instituição.
          </p>
        </div>
      </section>
      <section className={style.extraCourses}>
        <h3 className={style.title}>Cursos Extra-curriculares</h3>
        <ul className={style.list}>
          <li>
            Programa Oracle Next Education - Programação Front-End - setembro de
            2023
          </li>
          <li>Alura - Formação React - setembro 2023</li>
          <li>
            Alura - Formação Desenvolva seu primeiro APP com React-Native -
            setembro 2023
          </li>
          <li>
            Udemy - Interação Humano-Computador e UX Design (Somente Teoria) -
            2021
          </li>
          <li>Udemy - Aprenda Unity 2D Definitivo - Janeiro 2019</li>
          <li>
            Udemy - Aprenda a criar jogos com a UNITY na prática - Novembro 2018
          </li>
          <li>
            Senac Minas - Montagem e Configuração de Redes Windows - Junho 2008
          </li>
          <li>
            Senac Minas - Montagem e Manutenção de Computadores - Abril de 2008
          </li>
          <li>
            Senac Minas - Introdução à Programação e Hardware - Abril 2008
          </li>
        </ul>
      </section>
    </main>
  );
}

export default Curriculum;
