import { HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Curriculum from "./pages/Curriculum";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MinhaFeiraPolicy from "./pages/MinhaFeiraPolicy";

function App() {
  return (
      <HashRouter >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Curriculum" element={<Curriculum />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/MinhaFeiraPolicy" element={<MinhaFeiraPolicy />} />

          <Route
            path="*"
            element={
              <div>
                <p>Página não encontrada!</p>
              </div>
            }
          />
        </Routes>
        <Footer/>
      </HashRouter>
  );
}

export default App;
