import style from "./Logo.module.css";

import logoImage from "./joao-logo.png"; // Importe a imagem dessa forma

function Logo() {
    return (
        <div className={style.logo}>
            <img className={style.logoImage} src={logoImage} alt="Imagem do logo da empresa" />
            <div className={style.logoText}>
                <h3 className={style.logoText}>Pixel</h3>
                <h3 className={style.logoText}>Joao</h3>
            </div>

        </div>

    );
}

export default Logo;
