import React from "react";
import style from "./PrivacyPolicy.module.css";

function MinhaFeiraPrivacyPolicy() {
    return (
        <main className={style.privacyPolicyContainer}>

            <section className={style.privacyPolicy}>
                <h1 className={style.title}>Política de Privacidade do Seu Aplicativo</h1>
                <p>
                    Agradecemos por escolher utilizar nosso aplicativo! Esta Política de Privacidade visa fornecer informações transparentes sobre como coletamos, usamos e protegemos seus dados durante o uso do nosso serviço.
                </p>

                <h2>1. Coleta de Dados:</h2>
                <p>
                    Coletamos informações como nome, sobrenome e senha exclusivamente para acesso ao sistema e facilitar a conexão com amigos e familiares. Esses dados são essenciais para garantir uma experiência personalizada e segura.
                </p>

                <h2>2. Uso das Informações:</h2>
                <p>
                    As informações coletadas são usadas apenas para fins de autenticação e conexão. Não compartilhamos seus dados com terceiros sem o seu consentimento.
                </p>

                <h2>3. Armazenamento:</h2>
                <p>
                    Seus dados são armazenados no Firebase, um serviço de armazenamento de objetos construído sobre a infraestrutura rápida e segura do Google Cloud. O Firebase SDK para Cloud Storage permite que você faça upload e download de arquivos diretamente dos clientes.
                </p>

                {/* Adicione mais detalhes sobre o armazenamento, se necessário */}

                <h2>4. Segurança:</h2>
                <p>
                    Implementamos medidas de segurança para proteger seus dados pessoais. Recomendamos que você escolha senhas fortes e não compartilhe suas credenciais com terceiros.
                </p>

                <h2>5. Atualizações:</h2>
                <p>
                    Manteremos você informado sobre quaisquer mudanças na política de privacidade. Sempre que houver atualizações, você será notificado.
                </p>

                <h2>6. Contato:</h2>
                <p>
                    Para quaisquer dúvidas relacionadas à privacidade, você pode entrar em contato conosco através do seguinte e-mail: <a href="mailto:jpm.mendonca@gmail.com">jpm.mendonca@gmail.com</a>.
                </p>

                <p>
                    Agradecemos por confiar em nós para fornecer uma experiência segura. Se houver alguma dúvida ou preocupação em relação à sua privacidade, não hesite em nos contatar.
                </p>
                <br></br>
                <p>
                    Para solicitar a exclusão de sua conta, encaminhe um e-mail para{" "}
                    <a
                        href="mailto:jpm.mendonca@gmail.com?subject=Exclus%C3%A3o%20de%20Conta"
                        className={style.emailLink} // Adicione a classe para estilizar o link
                    >
                        jpm.mendonca@gmail.com
                    </a>{" "}
                    com o título: Exclusão de Conta
                </p>
                <p className={style.updatedDate}>
                    Atualizado em 19 de fevereiro de 2024.
                </p>

            </section>
        </main>
    );
}

export default MinhaFeiraPrivacyPolicy;
