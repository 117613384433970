import React from "react";
import style from "./Services.module.css";

import bannerImage from "./banner-servicos.jpg";

function Services() {
  return (
    <main className={style.servicesContainer}>
      <section className={style.service}>
        <h2 className={style.title}>Desenvolvimento de Sites</h2>
        <p className={style.description}>
          Ofereço serviços de desenvolvimento de sites de alta qualidade,
          utilizando as mais recentes tecnologias web. Crie uma presença online
          sólida para o seu negócio ou projeto pessoal.
        </p>
      </section>
      <section className={style.service}>
        <h2 className={style.title}>Desenvolvimento de APPs</h2>
        <p className={style.description}>
          Desenvolvo aplicativos móveis personalizados para atender às suas
          necessidades. Seja para Android ou iOS, estou pronto para criar a
          solução ideal para o seu aplicativo.
        </p>
      </section>
      <section className={style.service}>
        <h2 className={style.title}>Desenvolvimento de Jogos</h2>
        <p className={style.description}>
          Com experiência em desenvolvimento de jogos, posso criar jogos
          incríveis para diversas plataformas. Desde jogos para dispositivos
          móveis até jogos para PC e consoles, estou pronto para tornar suas
          ideias em realidade.
        </p>
      </section>
      <div className={style.image}>
        <img
          className={style.servicesBanner}
          src={bannerImage}
          alt="banner de serviços"
        ></img>
      </div>
    </main>
  );
}

export default Services;
