import style from "./Footer.module.css";

function Footer() {
  return (
    <footer className={style.footer}>
      <div className={style.footerContainer}>
        <div className={style.footerText}>
          <p>© 2023 João Paulo Mendonça Prado</p>
          <div>
            <p>Informações de Contato:</p>
            <p>Email: contato@pixeljoao.com.br</p>
            <p>Telefone: (81) 99755-0684</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
