import { Link, useLocation } from "react-router-dom";
import Logo from "../Logo";
import style from "./Header.module.css";

function Header() {
  const location = useLocation();

  console.log(location);
  
  return (
    <header className={style.header}>
      <div className={style.headerContainer}>
        <Logo className={style.headerLogo} />
        <nav>
  <ul className={style.headerMenu}>
    <li>
      <Link
        className={`${style.headerLink} ${
          location.pathname === "/" ? style.headerLinkHighlighted : ""
        }`}
        to="/"
      >
        Home
      </Link>
    </li>
    <hr />
    <li>
      <Link
        className={`${style.headerLink} ${
          location.pathname === "/curriculum" ? style.headerLinkHighlighted : ""
        }`}
        to="/curriculum"
      >
        Currículo
      </Link>
    </li>
    <hr />
    <li>
      <Link
        className={`${style.headerLink} ${
          location.pathname === "/portfolio" ? style.headerLinkHighlighted : ""
        }`}
        to="/portfolio"
      >
        Portfólio
      </Link>
    </li>
    <hr />
    <li>
      <Link
        className={`${style.headerLink} ${
          location.pathname === "/services" ? style.headerLinkHighlighted : ""
        }`}
        to="/services"
      >
        Serviços
      </Link>
    </li>
    <hr />
    <li>
      <Link
        className={`${style.headerLink} ${
          location.pathname === "/contact" ? style.headerLinkHighlighted : ""
        }`}
        to="/contact"
      >
        Contato
      </Link>
    </li>
  </ul>
</nav>

      </div>
    </header>
  );
}

export default Header;
