import React from "react";
import Project from "../../components/Project";
import style from "./Portfolio.module.css";
import projectData from "../../data/projects.json"; // Importe o arquivo JSON

function Portfolio() {
  // Agrupe os projetos por tipo
  const projectsByType = {};

  projectData.forEach((project) => {
    const projectType = project.type;

    if (!projectsByType[projectType]) {
      projectsByType[projectType] = [];
    }

    projectsByType[projectType].push(project);
  });

  return (
    <main>
      <div className={style.portfolioContainer}>
        {/* Renderize seções para cada tipo de projeto */}
        {Object.entries(projectsByType).map(([type, projects]) => (
          <section key={type} className={style.portfolioCategory}>
            <h3 className={style.portfolioType}>{type}</h3>
            <div className={style.projectList}>
              {projects.map((project) => (
                <Project
                  key={project.id}
                  title={project.title}
                  description={project.description}
                  credits={project.credits}
                  media={project.media}
                  PrivacyTerms={project.PrivacyTerms} 
                />
              ))}
            </div>
          </section>
        ))}
      </div>
    </main>
  );
}

export default Portfolio;
