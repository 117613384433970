import React from "react";
import style from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
    return (
        <main className={style.privacyPolicyContainer}>

            <section className={style.privacyPolicy}>
                <h1 className={style.title}>Política de Privacidade - Fresh Chicken Games: Super Duck Madness</h1>
                <p>
                    Agradecemos por escolher jogar Super Duck Madness, um jogo desenvolvido por Fresh Chicken Games! Esta Política de Privacidade visa fornecer informações transparentes sobre como coletamos, usamos e protegemos seus dados enquanto você desfruta do nosso jogo.
                </p>

                <h2>1. Informações Coletadas:</h2>
                <p>
                    ID de Anúncio: Coletamos o ID de anúncio para personalizar a experiência publicitária no jogo.
                </p>
                <p>
                    Dados do Google Play Games: Para melhorar sua experiência, acessamos conquistas e, futuramente, podemos implementar o salvamento de jogos na nuvem usando o serviço do Google Play Games.
                </p>

                <h2>2. Armazenamento na Nuvem:</h2>
                <p>
                    Planejamos implementar o salvamento do jogo nas nuvens usando o serviço do Google. Esta funcionalidade permitirá que você acesse seu progresso em diferentes dispositivos.
                </p>

                <h2>3. Uso Responsável:</h2>
                <p>
                    Comprometemo-nos a utilizar suas informações de forma responsável e exclusivamente para aprimorar sua experiência de jogo.
                </p>

                <h2>4. Política de Privacidade Disponível:</h2>
                <p>
                    Nossa Política de Privacidade está disponível no link fornecido na página "Detalhes do app" no Google Play e dentro do próprio jogo.
                </p>

                <h2>5. Transparência:</h2>
                <p>
                    Nosso compromisso é garantir total transparência. A Política de Privacidade destaca claramente as informações coletadas, os propósitos e os procedimentos seguros de processamento.
                </p>

                <h2>6. Contato de Privacidade:</h2>
                <p>
                    Para quaisquer dúvidas relacionadas à privacidade, você pode entrar em contato conosco através do seguinte e-mail: privacy@freshchickengames.com.
                </p>

                <p>
                    Agradecemos por confiar em Fresh Chicken Games para fornecer uma experiência de jogo divertida e segura. Se houver alguma dúvida ou preocupação em relação à sua privacidade, não hesite em nos contatar.
                </p>

                <p className={style.updatedDate}>
                    Atualizado em 17 de novembro de 2023.
                </p>

            </section>
        </main>
    );
}

export default PrivacyPolicy;
