import React, { useState } from "react";
import style from "./Contact.module.css";
import contactImage from "./banner-tecnologia.jpg";
import { useForm, ValidationError } from "@formspree/react";

function Contact() {
  // Defina os estados para os campos do formulário
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // UseForm para gerenciar o formulário com Formspree
  const [state, handleSubmit] = useForm("xqkvgege");

  // Estado para controlar erros
  const [errors, setErrors] = useState({});

  // Função para lidar com o envio do formulário
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Enviar o formulário usando Formspree
    handleSubmit(e);

    // Verificar se há erros e definir os erros no estado
    if (state.errors) {
      const fieldErrors = {};
      state.errors.forEach((error) => {
        fieldErrors[error.field] = error.message;
      });
      setErrors(fieldErrors);
    }

    // Exibir um alerta de sucesso apenas se não houver erros
    if (state.succeeded && !state.errors) {
      alert("Mensagem enviada com sucesso!");

      // Limpar os campos após o envio
      setName("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <main className={style.contactContainer}>
      <h2 className={style.title}>Entre em Contato</h2>
      <p className={style.description}>
        Tem alguma pergunta ou solicitação? Ficarei feliz em ouvir de você.
        Preencha o formulário abaixo e entrarei em contato em breve.
      </p>
      <form className={style.contactForm} onSubmit={handleFormSubmit}>
        <div className={style.formGroup}>
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {/* Exibir erro de validação, se houver */}
          {errors.name && <ValidationError prefix="Name" field="name" errors={state.errors} />}
        </div>
        <div className={style.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {/* Exibir erro de validação, se houver */}
          {errors.email && <ValidationError prefix="Email" field="email" errors={state.errors} />}
        </div>
        <div className={style.formGroup}>
          <label htmlFor="message">Mensagem:</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          {/* Exibir erro de validação, se houver */}
          {errors.message && <ValidationError prefix="Message" field="message" errors={state.errors} />}
        </div>
        <div className={style.formGroup}>
          <button type="submit">Enviar Mensagem</button>
        </div>
      </form>
      <div className={style.image}>
        <img
          className={style.contactImage}
          src={contactImage}
          alt="banner de serviços"
        />
      </div>
    </main>
  );
}

export default Contact;
